
body{
    background-color: #282c34;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


.App {
    text-align: center;
    height: 100%;
    background-color: #282c34;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(1em + 2vmin);
    color: white;
  }

.App-header {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
.animeGrid{

    display: grid;
    grid-template-columns: repeat(3, 1fr);
    width: fit-content;
    gap: 0px; /* adjust as needed */
    justify-items: center;
    align-items: center;
    margin-top: 15px;
    margin-bottom: 20px;
    padding: 20px;
}
button{
    margin: 10px;
    padding: 10px;
    border-radius: 5px;
    background-color: hsla(202, 68%, 39%, 0.884);
    color: white;
    font-size: 1.5rem;
    cursor: pointer;
    transition: 0.25s ease;
    border-radius: 8px;
}
button:hover{
    background-color: hsla(212, 81%, 23%, 0.822);
    color: white;
    box-shadow: inset 0 0px 30px  10px hsl(202, 68%, 39%);
    
}
#importantInfo{
    width: 120px;
    height: 120px;
    border-radius: 50%;
    transition : 0.25s ease;
    position: fixed;
    cursor: pointer;
    z-index: 10;
  bottom: 50px; 
  right: 200px; 
    
}
#importantInfo:hover{
    transform: scale(1.3);
    box-shadow: 0 0 40px #02d9e0;
    transition : 0.25s ease;
}
#sidebar {
    position: fixed;
    right: 50px;
    top: 0;
    width: 0%;
    height: 100%;
    overflow: hidden;
    transition: width 0.5s ease;
    background-color: #f9f9f9;
    color: black;
    padding: 20px;
    margin: 0px;
    border-radius: 10px;
    animation: slideOut 0.25s ease forwards;
  }
  
  #sidebar.open {
    width: 100%; 
    max-width: 400px; /* Max width for responsiveness */
    z-index: 5;
    animation: slideIn 0.25s ease forwards;
  }
  #sidebar h2{
    margin: 10px;
    padding: 0px;
    font-size: 31px;
  }
    #sidebar p{
        margin: 0px;
        padding: 0px;
        font-size: 24px;
    }
    #sidebar a{
        text-decoration: none;
        color: #1dbcd7;
        font-size: 24px;
        
    }
    
  @keyframes slideIn {
    0% {
      transform: translateY(100%);
    }
    100% {
      transform: translateY(0);
    }
  }
  
  @keyframes slideOut {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(100%);
    }
  }
  /* Responsive styles */
  @media (max-width: 1200px) {

    #importantInfo {
        width: 100px;
        height: 100px;
        right: 10%; /* Center horizontally */
    transform: translateX(50%);
    }

    #sidebar {
        right: 20px;
        padding: 10px;
    }
    #sidebar p {
        font-size: 22px; /* Reduce font size for smaller screens */
    }
    #sidebar h2{
        margin: 10px;
        padding: 0px;
        font-size: 27px;
      }

}
@media (max-width: 768px) {

    #importantInfo {
        width: 80px;
        height: 80px;
        right: 10%; /* Center horizontally */
    transform: translateX(50%);
    }

    #sidebar {
        right: 20px;
        padding: 10px;
    }
    #sidebar p {
        font-size: 18px; /* Reduce font size for smaller screens */
        padding: 10px;
    }
    #sidebar h2{
        margin: 10px;
        padding: 0px;
        font-size: 25px;
      }
}
@media (max-width: 576px) {

    #importantInfo {
        width: 60px;
        height: 60px;
        right: 10%; /* Center horizontally */
    transform: translateX(50%);
    }

    #sidebar {
        right: 0px;
        padding: 5px;
    }
    #sidebar p {
        font-size: 14px; /* Further reduce font size for even smaller screens */
        padding: 5px;
    }
    #sidebar h2{
        margin: 10px;
        padding: 0px;
        font-size: 21px;
      }
}
