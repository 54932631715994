/* Default styles */
.animeImage {
  width: fit-content; /* adjust as needed */
  max-width: 250px;
  height: 300px; /* adjust as needed */
  object-fit: fill;
  border-radius: 5px;
}

#searchAnime {
  margin: 10px;
  padding: 10px;
  border-radius: 5px;
  cursor: text;
  color: black;
  font-size: 1.5rem;
  transition: 0.25s ease;
  border-radius: 8px;
}

#searchOptions {
  display: flex;
  justify-content: center;
  align-items: center;
}

#clearIcon {
  width: 25px;
  height: 25px;
  fill: #1dbcd7;
  cursor: pointer;
  transition: 0.25s ease;
}

#clearIcon:hover {
  transform: scale(1.3);
  fill: red;
}

/* Responsive styles */
@media (max-width: 768px) {
  .animeImage {
      width: 200px;
      height: 250px;
  }

  #searchAnime {
      font-size: 1.2rem;
  }
}

@media (max-width: 576px) {
  .animeImage {
      width: 150px;
      height: 200px;
  }

  #searchAnime {
      font-size: 1rem;
  }
}
